package de.ohhhmhhh.backend.sdk.authentication

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*

class AuthenticationServiceImpl(private val client: HttpClient) : AuthenticationService {
    override suspend fun login(mail: String, password: String): String {
        return client.get("/authentication/login") {
            basicAuth(mail, password)
        }.body()
    }

    override suspend fun confirm(code: String): String {
        return client.get("/authentication/confirm") {
            parameter("code", code)
        }.body()
    }

    override suspend fun impersonate(userId: String): String {
        return client.get("/authentication/login") {
            parameter("user_id", userId)
        }.body()
    }

    override suspend fun validate() {
        return client.get("/authentication/validate").body()
    }
}